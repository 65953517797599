import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { MapViewSkeleton } from "../shared/SkeletonLoader";
import { cn } from "../../lib/utils";

const MapView = ({ meteorites, selectedName, setSelectedName, loading }) => {
  if (loading) return <MapViewSkeleton />;

  const mapStyle = {
    height: "100%",
    width: "100%",
    filter: "invert(90%) hue-rotate(180deg) brightness(95%) contrast(85%)",
  };

  return (
    <section
      className={cn(
        "relative h-[400px] w-full overflow-hidden rounded-base",
        "bg-main border-2 border-border dark:border-darkBorder",
        "shadow-light dark:shadow-dark transition-transform",
        "hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none dark:hover:shadow-none"
      )}
    >
      <MapContainer
        center={[20, 0]}
        zoom={2}
        style={mapStyle}
        className={cn(
          "h-full w-full rounded-base",
          "[&_.leaflet-container]:bg-darkBg",
          "[&_.leaflet-popup-content-wrapper]:bg-main",
          "[&_.leaflet-popup-content-wrapper]:text-text dark:[&_.leaflet-popup-content-wrapper]:text-darkText",
          "[&_.leaflet-popup-tip]:bg-main",
          "[&_.leaflet-popup-content-wrapper]:border-2 [&_.leaflet-popup-content-wrapper]:border-border dark:[&_.leaflet-popup-content-wrapper]:border-darkBorder",
          "[&_.leaflet-popup-content-wrapper]:shadow-light dark:[&_.leaflet-popup-content-wrapper]:shadow-dark"
        )}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright" class="text-text hover:text-mainAccent transition-colors">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {meteorites.map((meteorite) => {
          if (meteorite.reclat && meteorite.reclong) {
            return (
              <Marker
                key={meteorite.id}
                position={[
                  parseFloat(meteorite.reclat),
                  parseFloat(meteorite.reclong),
                ]}
                eventHandlers={{
                  click: () => {
                    setSelectedName(meteorite.name);
                  },
                }}
              >
                <Popup>
                  <div className="p-4">
                    <h3 className="mb-4 font-heading text-mainAccent">
                      {meteorite.name}
                    </h3>
                    <div className="space-y-2">
                      <p className="flex justify-between">
                        <span className="font-base text-text/75 dark:text-darkText/75">
                          Mass:
                        </span>
                        <span className="font-base text-text dark:text-darkText">
                          {meteorite.mass}g
                        </span>
                      </p>
                      <p className="flex justify-between">
                        <span className="font-base text-text/75 dark:text-darkText/75">
                          Year:
                        </span>
                        <span className="font-base text-text dark:text-darkText">
                          {new Date(meteorite.year).getFullYear()}
                        </span>
                      </p>
                      <p className="flex justify-between">
                        <span className="font-base text-text/75 dark:text-darkText/75">
                          Class:
                        </span>
                        <span className="font-base text-text dark:text-darkText">
                          {meteorite.recclass}
                        </span>
                      </p>
                    </div>
                  </div>
                </Popup>
              </Marker>
            );
          }
          return null;
        })}
      </MapContainer>
    </section>
  );
};

export default MapView;
