import React from "react";

const OrderControls = ({
  sortOrder,
  setSortOrder,
  sortField,
  setSortField,
}) => {
  return (
    <fieldset className="p-4 space-y-4">
      <div className="flex flex-col gap-1">
        <label
          htmlFor="sortField"
          className="text-black font-heading font-bold"
        >
          Sort By:
        </label>
        <select
          id="sortField"
          value={sortField}
          onChange={(e) => setSortField(e.target.value)}
          className="w-full bg-white border-2 border-border rounded-base px-3 py-1.5
                     focus:outline-none focus:ring-2 focus:ring-mainAccent"
        >
          <option value="name">Name</option>
          <option value="mass">Mass</option>
          <option value="year">Year</option>
        </select>
      </div>

      <div className="space-y-2">
        <legend className="text-black font-heading font-bold">Order:</legend>
        <div className="flex gap-4">
          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="sortOrder"
              value="asc"
              checked={sortOrder === "asc"}
              onChange={(e) => setSortOrder(e.target.value)}
              className="accent-mainAccent"
            />
            <span className="text-black font-medium">Ascending</span>
          </label>

          <label className="flex items-center gap-2">
            <input
              type="radio"
              name="sortOrder"
              value="desc"
              checked={sortOrder === "desc"}
              onChange={(e) => setSortOrder(e.target.value)}
              className="accent-mainAccent"
            />
            <span className="text-black font-medium">Descending</span>
          </label>
        </div>
      </div>
    </fieldset>
  );
};

export default OrderControls;
