import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartSectionSkeleton } from "../shared/SkeletonLoader";
import { cn } from "../../lib/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend
);

const ChartSection = ({ meteorites, loading }) => {
  if (loading) return <ChartSectionSkeleton />;

  const getMassDistribution = () => {
    const ranges = {
      "0-100g": 0,
      "101-500g": 0,
      "501-1000g": 0,
      "1001-10000g": 0,
      ">10000g": 0,
    };

    meteorites.forEach((meteorite) => {
      const mass = parseFloat(meteorite.mass) || 0;
      if (mass <= 100) ranges["0-100g"]++;
      else if (mass <= 500) ranges["101-500g"]++;
      else if (mass <= 1000) ranges["501-1000g"]++;
      else if (mass <= 10000) ranges["1001-10000g"]++;
      else ranges[">10000g"]++;
    });
    return ranges;
  };

  const massDistribution = getMassDistribution();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "currentColor",
          font: {
            family: "system-ui",
            weight: "800",
          },
        },
      },
      title: {
        display: true,
        text: "Meteorite Mass Distribution",
        font: {
          size: 16,
          family: "system-ui",
          weight: "800",
        },
        color: "currentColor",
      },
      tooltip: {
        callbacks: {
          label: (context) => `Count: ${context.formattedValue} meteorites`,
        },
        backgroundColor: "#FD9745",
        borderColor: "#000",
        borderWidth: 2,
        titleColor: "#000",
        bodyColor: "#000",
        padding: 12,
        cornerRadius: 5,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          color: "currentColor",
          font: {
            family: "system-ui",
            weight: "500",
          },
        },
        title: {
          display: true,
          text: "Number of Meteorites",
          color: "currentColor",
          font: {
            family: "system-ui",
            weight: "800",
          },
        },
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
          borderWidth: 2,
        },
        border: {
          width: 2,
        },
      },
      x: {
        title: {
          display: true,
          text: "Mass Range",
          color: "currentColor",
          font: {
            family: "system-ui",
            weight: "800",
          },
        },
        ticks: {
          color: "currentColor",
          font: {
            family: "system-ui",
            weight: "500",
          },
        },
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
          borderWidth: 2,
        },
        border: {
          width: 2,
        },
      },
    },
  };

  const chartData = {
    labels: Object.keys(massDistribution),
    datasets: [
      {
        label: "Number of Meteorites",
        data: Object.values(massDistribution),
        backgroundColor: "#FD9745",
        borderColor: "#000",
        borderWidth: 2,
        borderRadius: 5,
      },
    ],
  };

  return (
    <section
      aria-label="Meteorite Mass Distribution Chart"
      className={cn(
        "h-[400px] w-full p-6 rounded-base",
        "bg-bg dark:bg-darkBg border-2 border-border dark:border-darkBorder",
        "shadow-light dark:shadow-dark transition-transform",
        "hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none dark:hover:shadow-none"
      )}
    >
      <Bar options={options} data={chartData} />
    </section>
  );
};

export default ChartSection;
