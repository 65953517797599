import { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const CACHE_TIME = 1000 * 60 * 5; // 5 minutes

export const useMeteoriteData = () => {
  const [filteredMeteorites, setFilteredMeteorites] = useState([]);

  const [filters, setFilters] = useState({
    date: "",
    mass: "",
    name: "",
  });

  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField, setSortField] = useState("name");

  const fetchMeteorites = async () => {
    const response = await axios.get(
      "https://data.nasa.gov/resource/gh4g-9sfh.json"
    );
    const meteorData = response.data
        .map((item) => ({
          name: item.name,
          id: item.id,
          nametype: item.nametype,
          recclass: item.recclass,
          mass: item.mass,
          fall: item.fall,
          year: item.year,
          reclat: item.reclat,
          reclong: item.reclong,
          geolocation: {
            latitude: item.reclat,
            longitude: item.reclong,
          },
        }))
        .filter(
          (item) => item.reclat && item.reclong && item.mass && item.year
        );

    return meteorData;
  };

  const { data: meteorites = [], isLoading: loading, error } = useQuery({
    queryKey: ['meteorites'],
    queryFn: fetchMeteorites,
    staleTime: CACHE_TIME,
    cacheTime: CACHE_TIME,
    retry: 2,
    suspense: true,
  });

  useEffect(() => {
    if (meteorites.length > 0) {
      const processed = getSortedFilteredMeteorites();
      setFilteredMeteorites(processed);
    }
  }, [filters, sortOrder, sortField, meteorites]);

  const getSortedFilteredMeteorites = () => {
    const filtered = getFilteredMeteorites();

    return filtered.sort((x, y) => {
      let compareX, compareY;

      switch (sortField) {
        case "mass":
          compareX = parseFloat(x.mass) || 0;
          compareY = parseFloat(y.mass) || 0;
          break;

        case "year":
          compareX = new Date(x.year).getTime();
          compareY = new Date(y.year).getTime();
          break;

        default:
          compareX = x.name.toLowerCase();
          compareY = y.name.toLowerCase();
      }

      if (sortOrder === "asc") {
        return compareX < compareY ? -1 : compareX > compareY ? 1 : 0;
      } else {
        return compareY < compareX ? -1 : compareY > compareX ? 1 : 0;
      }
    });
  };

  const getFilteredMeteorites = () => {
    return meteorites.filter((meteorite) => {
      const yearMatch =
        !filters.date ||
        new Date(meteorite.year)
          .getFullYear()
          .toString()
          .includes(filters.date);
      const massMatch =
        !filters.mass || parseFloat(meteorite.mass) >= parseFloat(filters.mass);
      const nameMatch =
        !filters.name ||
        meteorite.name.toLowerCase().includes(filters.name.toLowerCase());

      return yearMatch && massMatch && nameMatch;
    });
  };

  const clearAllFilters = () => {
    setFilters({ date: "", mass: "", name: "" });
    setSortOrder("asc");
    setSortField("name");
    setFilteredMeteorites(meteorites);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return {
    meteorites: filteredMeteorites,
    loading,
    error,
    filters,
    setFilters,
    sortOrder,
    setSortOrder,
    sortField,
    setSortField,
    clearAllFilters,
    handleRefresh,
  };
};

export default useMeteoriteData;
