import { useState, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useMeteoriteData from "./hooks/useMeteoritesData";
import MainLayout from "./components/layout/MainLayout";
import Header from "./components/layout/Header";
import { ErrorBoundary } from "react-error-boundary";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const AppContent = () => {
  const {
    meteorites,
    loading,
    error,
    filters,
    setFilters,
    sortOrder,
    setSortOrder,
    sortField,
    setSortField,
    clearAllFilters,
  } = useMeteoriteData();

  const [selectedName, setSelectedName] = useState(null);
  
  const handleRefresh = () => {
    window.location.reload();
  };

  if (error) {
    return (
      <main className="app">
        <Header />
        <div className="error-message">
          {error}
          <button onClick={handleRefresh}>Retry</button>
        </div>
      </main>
    );
  }

  return (
    <ErrorBoundary
      fallback={
        <div className="error-message">
          Something went wrong. Please try again later.
          <button onClick={() => window.location.reload()}>Refresh</button>
        </div>
      }
    >
      <Suspense fallback={<div>Loading...</div>}>
        <MainLayout
          meteorites={meteorites}
          loading={loading}
          error={error}
          filters={filters}
          setFilters={setFilters}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          sortField={sortField}
          setSortField={setSortField}
          clearAllFilters={clearAllFilters}
          selectedName={selectedName}
          setSelectedName={setSelectedName}
          handleRefresh={handleRefresh}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContent />
    </QueryClientProvider>
  );
};

export default App;
