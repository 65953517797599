import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { X } from "lucide-react";
import MeteoriteCard from "./MeteoriteCard";
import { cn } from "../../lib/utils";

const RandomMeteorite = ({ meteorites }) => {
  const [randomMeteorite, setRandomMeteorite] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [active, setActive] = useState(false);

  const getRandomMeteorite = () => {
    const randomIndex = Math.floor(Math.random() * meteorites.length);
    setRandomMeteorite(meteorites[randomIndex]);
    setActive(true);
  };

  const closeModal = () => {
    setIsVisible(false);
    setTimeout(() => {
      setActive(false);
    }, 300);
  };

  useEffect(() => {
    if (active) {
      setIsVisible(true);
    }
  }, [active]);

  const Modal = () => {
    if (!active) return null;

    return createPortal(
      <div
        role="dialog"
        aria-modal="true"
        data-visible={isVisible ? "true" : "false"}
        onClick={closeModal}
        className={cn(
          "fixed inset-0 z-50 flex items-center justify-center",
          "bg-overlay backdrop-blur-sm transition-all duration-300",
          "data-[visible=true]:opacity-100 data-[visible=true]:visible",
          "data-[visible=false]:opacity-0 data-[visible=false]:invisible"
        )}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          data-visible={isVisible}
          className={cn(
            "relative w-[90vw] max-w-[400px] p-6",
            "flex flex-col items-center justify-center",
            "rounded-base bg-bg dark:bg-darkBg border-2 border-border dark:border-darkBorder",
            "shadow-light dark:shadow-dark transition-all duration-300",
            "data-[visible=true]:translate-y-0 data-[visible=true]:scale-100",
            "data-[visible=false]:translate-y-4 data-[visible=false]:scale-95"
          )}
        >
          <button
            onClick={closeModal}
            className={cn(
              "absolute -top-3 -right-3 w-10 h-10 flex items-center justify-center",
              "rounded-full bg-mainAccent text-text dark:text-darkText border-2 border-border dark:border-darkBorder",
              "shadow-light dark:shadow-dark transition-all",
              "hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none dark:hover:shadow-none"
            )}
          >
            <X className="h-5 w-5" />
          </button>

          <div className="absolute -inset-1 -z-10 rounded-base bg-text/5 blur-lg" />

          <div className="w-full">
            <MeteoriteCard
              meteorite={randomMeteorite}
              isSelected={false}
              onSelect={() => {}}
            />
          </div>
        </div>
      </div>,
      document.getElementById("modal")
    );
  };

  return (
    <>
      <button
        onClick={getRandomMeteorite}
        className={cn(
          "group relative w-full px-4 py-3 font-heading text-text dark:text-darkText",
          "bg-mainAccent border-2 border-border dark:border-darkBorder rounded-base",
          "shadow-light dark:shadow-dark transition-all duration-200",
          "hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none dark:hover:shadow-none"
        )}
      >
        <span className="relative z-10 flex items-center justify-center gap-2">
          <span className="transition-transform group-hover:scale-105">
            Show me a random meteorite!
          </span>
        </span>
        <div className="absolute inset-0 rounded-base bg-text/5 opacity-0 transition-opacity group-hover:opacity-100" />
      </button>
      <Modal />
    </>
  );
};

export default RandomMeteorite;
