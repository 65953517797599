import { cn } from "@/lib/utils";

export default function Header() {
  return (
    <header className="w-full bg-main border-b-2 border-border py-6 px-8 shadow-light relative z-10">
      <div className="container mx-auto">
        <h1
          className={cn(
            "text-5xl font-heading text-center text-text tracking-wide",
            "hover:scale-105 transition-transform duration-300"
          )}
        >
          Meteorite{" "}
          <span className="relative text-darkText">
            Box
            <span className="absolute -inset-1 bg-text/10 rounded-base blur-sm -z-10"></span>
          </span>
        </h1>
        <p className="mt-2 text-center text-sm font-base text-text/75">
          Marvel at the space rocks
        </p>
      </div>
    </header>
  );
}
