import React from "react";

const SearchControls = ({ filters, setFilters, clearAll }) => {
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <form className="space-y-4 p-4">
      <div className="space-y-3">
        <div className="flex flex-col gap-1">
          <label htmlFor="date" className="text-black font-heading font-bold">
            Year:
          </label>
          <input
            type="number"
            id="date"
            name="date"
            value={filters.date}
            onChange={handleFilterChange}
            placeholder="Enter year"
            className="w-full bg-white border-2 border-border rounded-base px-3 py-1.5
                       focus:outline-none focus:ring-2 focus:ring-mainAccent placeholder:text-gray-500"
          />
        </div>

        <div className="flex flex-col gap-1">
          <label htmlFor="mass" className="text-black font-heading font-bold">
            Minimum Mass (g):
          </label>
          <input
            type="number"
            id="mass"
            name="mass"
            value={filters.mass}
            onChange={handleFilterChange}
            placeholder="Enter Minimum Mass"
            className="w-full bg-white border-2 border-border rounded-base px-3 py-1.5
                       focus:outline-none focus:ring-2 focus:ring-mainAccent placeholder:text-gray-500"
          />
        </div>

        <div className="flex flex-col gap-1">
          <label htmlFor="name" className="text-black font-heading font-bold">
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={filters.name}
            onChange={handleFilterChange}
            placeholder="Enter Meteorite name"
            className="w-full bg-white border-2 border-border rounded-base px-3 py-1.5
                       focus:outline-none focus:ring-2 focus:ring-mainAccent placeholder:text-gray-500"
          />
        </div>
      </div>

      <button
        type="button"
        onClick={clearAll}
        className="w-full bg-mainAccent text-black font-bold border-2 border-border rounded-base px-4 py-1.5
                   font-heading shadow-light hover:shadow-none hover:translate-x-boxShadowX
                   hover:translate-y-boxShadowY transition-transform"
      >
        Clear All
      </button>
    </form>
  );
};

export default SearchControls;
