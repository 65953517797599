import React from "react";
import { cn } from "../../lib/utils";

const MeteoriteCard = ({ meteorite, isSelected, onSelect }) => {
  return (
    <article
      onClick={onSelect}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onSelect();
        }
      }}
      tabIndex={0}
      role="button"
      aria-pressed={isSelected}
      className={cn(
        "relative cursor-pointer transition-all duration-200",
        "p-4",
        "rounded-base bg-main border-2 border-border dark:border-darkBorder shadow-light dark:shadow-dark",
        "hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none dark:hover:shadow-none",
        isSelected && "ring-2 ring-text"
      )}
    >
      <h2
        id={`meteorite-${meteorite.id}`}
        className={cn(
          "mb-4 text-xl font-heading text-text dark:text-darkText",
          "group-hover:scale-105 transition-transform"
        )}
      >
        {meteorite.name}
        {isSelected && (
          <span className="absolute -inset-1 bg-text/10 rounded-base blur-sm -z-10"></span>
        )}
      </h2>

      <ul className="space-y-2">
        <li className="flex items-center justify-between gap-4 p-2 rounded-base hover:bg-mainAccent/10 transition-colors">
          <span className="text-sm font-heading text-text/75 dark:text-darkText/75">
            Mass:
          </span>
          <span className="font-medium text-text dark:text-darkText">
            {meteorite.mass}g
          </span>
        </li>
        <li className="flex items-center justify-between gap-4 p-2 rounded-base hover:bg-mainAccent/10 transition-colors">
          <span className="text-sm font-heading text-text/75 dark:text-darkText/75">
            Year:
          </span>
          <span className="font-medium text-text dark:text-darkText">
            {new Date(meteorite.year).getFullYear()}
          </span>
        </li>
        <li className="flex items-center justify-between gap-4 p-2 rounded-base hover:bg-mainAccent/10 transition-colors">
          <span className="text-sm font-heading text-text/75 dark:text-darkText/75">
            Class:
          </span>
          <span className="font-medium text-text dark:text-darkText">
            {meteorite.recclass}
          </span>
        </li>
        <li className="flex items-center justify-between gap-4 p-2 rounded-base hover:bg-mainAccent/10 transition-colors">
          <span className="text-sm font-heading text-text/75 dark:text-darkText/75">
            Coordinates:
          </span>
          <span className="font-medium text-right text-text dark:text-darkText">
            {meteorite.reclat}, {meteorite.reclong}
          </span>
        </li>
        <li className="flex items-center justify-between gap-4 p-2 rounded-base hover:bg-mainAccent/10 transition-colors">
          <span className="text-sm font-heading text-text/75 dark:text-darkText/75">
            Fall:
          </span>
          <span className="font-medium text-text dark:text-darkText">
            {meteorite.fall}
          </span>
        </li>
      </ul>

      {isSelected && (
        <div className="pointer-events-none absolute -inset-px rounded-base border-2 border-text"></div>
      )}
    </article>
  );
};

export default MeteoriteCard;
