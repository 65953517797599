import Header from "./Header";
import SearchControls from "../controls/SearchControls";
import OrderControls from "../controls/OrderControls";
import MapView from "../display/MapView";
import ChartSection from "../display/ChartSection";
import MeteoriteGrid from "../meteorites/MeteoriteGrid";
import RandomMeteorite from "../meteorites/RandomMeteorite";
import { cn } from "@/lib/utils";

export default function MainLayout({
  meteorites,
  loading,
  error,
  filters,
  setFilters,
  sortOrder,
  setSortOrder,
  sortField,
  setSortField,
  clearAllFilters,
  selectedName,
  setSelectedName,
  handleRefresh,
}) {
  if (error) {
    return (
      <main className="min-h-screen bg-bg dark:bg-darkBg">
        <Header />
        <div className="p-6 mx-auto max-w-md text-center">
          <div className="brutalist-card p-4">
            <p className="mb-4 text-text dark:text-darkText">{error}</p>
            <button
              onClick={handleRefresh}
              className="rounded-base bg-mainAccent px-4 py-2 font-heading text-text dark:text-darkText border-2 border-border dark:border-darkBorder shadow-light dark:shadow-dark transition-all hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none dark:hover:shadow-none"
            >
              Retry
            </button>
          </div>
        </div>
      </main>
    );
  }

  return (
    <div className="relative flex min-h-screen w-screen flex-col overflow-hidden bg-bg dark:bg-darkBg">
      <Header />

      <main className="flex-1 w-full overflow-y-auto">
        <div className="container mx-auto space-y-8 px-4 py-6 sm:px-6 lg:px-8">
          <nav className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div className="brutalist-card">
              <SearchControls
                filters={filters}
                setFilters={setFilters}
                clearAll={clearAllFilters}
              />
            </div>

            <div className="brutalist-card">
              <OrderControls
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortField={sortField}
                setSortField={setSortField}
              />
            </div>

            <div className="md:col-span-2 flex justify-center px-4">
              <div className="w-full max-w-md">
                <RandomMeteorite meteorites={meteorites} />
              </div>
            </div>
          </nav>

          <section className="flex flex-col gap-6 lg:flex-row">
            <article className="flex-1 space-y-6">
              <div className="brutalist-card">
                <MapView
                  meteorites={meteorites}
                  selectedName={selectedName}
                  setSelectedName={setSelectedName}
                  loading={loading}
                />
              </div>

              <div className="brutalist-card">
                <ChartSection
                  meteorites={meteorites}
                  filters={filters}
                  loading={loading}
                />
              </div>
            </article>

            <div className="brutalist-card lg:w-1/3">
              <MeteoriteGrid
                meteorites={meteorites}
                loading={loading}
                error={error}
                filters={filters}
                sortOrder={sortOrder}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
              />
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
