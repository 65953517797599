import React, { useState } from "react";
import { cn } from "../../lib/utils";
import MeteoriteCard from "./MeteoriteCard";
import { MeteoriteGridSkeleton } from "../shared/SkeletonLoader";

const itemsPerPage = 3;

const MeteoriteGrid = ({
  meteorites,
  loading,
  error,
  filters,
  sortOrder,
  selectedName,
  setSelectedName,
}) => {
  const [currPage, setCurrPage] = useState(1);

  if (loading) return <MeteoriteGridSkeleton />;

  if (error)
    return (
      <div
        className={cn(
          "text-text dark:text-darkText font-heading text-center p-4",
          "bg-main/50 rounded-base border-2 border-border dark:border-darkBorder"
        )}
      >
        Error: {error}
      </div>
    );

  if (!meteorites.length)
    return (
      <div
        className={cn(
          "text-text dark:text-darkText font-heading text-center p-4",
          "bg-main/50 rounded-base border-2 border-border dark:border-darkBorder"
        )}
      >
        No meteorites found that matched your search criteria
      </div>
    );

  const totalPages = Math.ceil(meteorites.length / itemsPerPage);
  const index0 = (currPage - 1) * itemsPerPage;
  const index1 = index0 + itemsPerPage;
  const currentMeteorites = meteorites.slice(index0, index1);

  const initPageNumbers = () => {
    const pages = [];
    const showPageCount = 5;
    let startPage = Math.max(1, currPage - Math.floor(showPageCount / 2));
    let endPage = startPage + showPageCount - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - showPageCount + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const PaginationButton = ({ onClick, disabled, children, active }) => (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cn(
        "min-w-[40px] h-[40px] px-2 py-1 rounded-base font-heading transition-all duration-200",
        "border-2 border-border dark:border-darkBorder",
        disabled
          ? "bg-bg dark:bg-darkBg text-text/50 dark:text-darkText/50 cursor-not-allowed border-border/50"
          : active
          ? "bg-mainAccent text-text dark:text-darkText border-text shadow-light dark:shadow-dark"
          : "bg-main text-text dark:text-darkText shadow-light dark:shadow-dark hover:translate-x-boxShadowX hover:translate-y-boxShadowY hover:shadow-none dark:hover:shadow-none"
      )}
    >
      {children}
    </button>
  );

  return (
    <article className="w-full p-4 space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-heading text-text dark:text-darkText">
          Meteorites Found:{" "}
          <span className="font-heading text-mainAccent">
            {meteorites.length}
          </span>
        </h2>
      </div>

      <div className="space-y-4">
        {currentMeteorites.map((meteorite) => (
          <MeteoriteCard
            key={meteorite.id}
            meteorite={meteorite}
            isSelected={selectedName === meteorite.name}
            onSelect={() => setSelectedName(meteorite.name)}
          />
        ))}
      </div>
      <div className="flex flex-col items-center gap-4 pt-4 border-t-2 border-border/30 dark:border-darkBorder/30">
        <div className="flex flex-wrap justify-center gap-2 px-4 max-w-full">
          <div className="flex gap-2">
            <PaginationButton
              onClick={() => setCurrPage(1)}
              disabled={currPage === 1}
            >
              First
            </PaginationButton>
            <PaginationButton
              onClick={() => setCurrPage((prev) => prev - 1)}
              disabled={currPage === 1}
            >
              Prev
            </PaginationButton>
          </div>
          <div className="flex flex-wrap justify-center gap-2">
            {initPageNumbers().map((pageNum) => (
              <PaginationButton
                key={pageNum}
                onClick={() => setCurrPage(pageNum)}
                active={currPage === pageNum}
              >
                {pageNum}
              </PaginationButton>
            ))}
          </div>
          <div className="flex gap-2">
            <PaginationButton
              onClick={() => setCurrPage((prev) => prev + 1)}
              disabled={currPage === totalPages}
            >
              Next
            </PaginationButton>
            <PaginationButton
              onClick={() => setCurrPage(totalPages)}
              disabled={currPage === totalPages}
            >
              Last
            </PaginationButton>
          </div>
        </div>
        <div className="text-sm font-base text-text/75 dark:text-darkText/75">
          Page{" "}
          <span className="font-heading text-text dark:text-darkText">
            {currPage}
          </span>{" "}
          of{" "}
          <span className="font-heading text-text dark:text-darkText">
            {totalPages}
          </span>
        </div>
      </div>
    </article>
  );
};

export default MeteoriteGrid;
