// const SkeletonLine = () => (
//   <div className="h-4 bg-gray-700 rounded animate-pulse" />
// );

// const SkeletonMeteoriteCard = () => (
//   <div className="bg-gray-800 rounded-lg p-4 border border-gray-700">
//     <div className="h-6 w-3/4 bg-gray-700 rounded animate-pulse mb-4" />
//     <div className="space-y-2">
//       {[...Array(5)].map((_, index) => (
//         <div key={index} className="flex items-center justify-between">
//           <div className="w-1/4 h-4 bg-gray-700 rounded animate-pulse" />
//           <div className="w-1/3 h-4 bg-gray-700 rounded animate-pulse" />
//         </div>
//       ))}
//     </div>
//   </div>
// );

// const MeteoriteGridSkeleton = () => (
//   <article className="space-y-6">
//     <div className="h-6 w-48 bg-gray-700 rounded animate-pulse" />
//     <div className="space-y-4">
//       {[...Array(5)].map((_, index) => (
//         <SkeletonMeteoriteCard key={index} />
//       ))}
//     </div>
//     <div className="flex justify-center">
//       <div className="w-3/4 h-8 bg-gray-700 rounded animate-pulse" />
//     </div>
//   </article>
// );

// const MapViewSkeleton = () => (
//   <div className="h-[400px] bg-gray-800 rounded-lg animate-pulse" />
// );

// const ChartSectionSkeleton = () => (
//   <div className="h-[400px] bg-gray-800 rounded-lg animate-pulse" />
// );

// export { SkeletonLine };
// export { SkeletonMeteoriteCard };
// export { MeteoriteGridSkeleton };
// export { MapViewSkeleton };
// export { ChartSectionSkeleton };

import React from "react";
import { cn } from "../../lib/utils";

const SkeletonLine = () => (
  <div
    className={cn(
      "h-4 rounded-base bg-main/50 border border-border/20 animate-pulse"
    )}
  />
);

const SkeletonMeteoriteCard = () => (
  <div
    className={cn(
      "rounded-base p-4 border-2 border-border dark:border-darkBorder bg-main/30",
      "shadow-light dark:shadow-dark animate-pulse"
    )}
  >
    {/* Title */}
    <div
      className={cn(
        "mb-4 h-6 w-3/4 rounded-base bg-main/50 border border-border/20 animate-pulse"
      )}
    />
    {/* Content */}
    <div className="space-y-3">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="flex items-center justify-between gap-4">
          {/* Label */}
          <div
            className={cn(
              "h-4 w-1/4 rounded-base bg-main/50 border border-border/20 animate-pulse"
            )}
          />
          {/* Value */}
          <div
            className={cn(
              "h-4 w-1/3 rounded-base bg-main/50 border border-border/20 animate-pulse"
            )}
          />
        </div>
      ))}
    </div>
  </div>
);

const MeteoriteGridSkeleton = () => (
  <article className="p-4 space-y-6">
    {/* Header */}
    <div
      className={cn(
        "h-6 w-48 rounded-base bg-main/50 border border-border/20 animate-pulse"
      )}
    />
    {/* Cards */}
    <div className="space-y-4">
      {[...Array(3)].map((_, index) => (
        <SkeletonMeteoriteCard key={index} />
      ))}
    </div>
    {/* Pagination */}
    <div className="flex flex-col items-center gap-4 pt-4 border-t-2 border-border/20 dark:border-darkBorder/20">
      {/* Pagination Buttons */}
      <div className="flex flex-wrap justify-center gap-2">
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            className={cn(
              "h-10 w-10 rounded-base bg-main/50 border border-border/20 animate-pulse"
            )}
          />
        ))}
      </div>
      {/* Page Counter */}
      <div
        className={cn(
          "h-4 w-24 rounded-base bg-main/50 border border-border/20 animate-pulse"
        )}
      />
    </div>
  </article>
);

const MapViewSkeleton = () => (
  <div
    className={cn(
      "relative h-[400px] overflow-hidden rounded-base border-2 border-border/20 dark:border-darkBorder/20",
      "bg-main/30 shadow-light dark:shadow-dark animate-pulse"
    )}
  >
    {/* Map Content Simulation */}
    <div className="absolute inset-0 grid grid-cols-3 gap-2 p-4">
      {[...Array(9)].map((_, index) => (
        <div
          key={index}
          className={cn(
            "rounded-base bg-main/50 border border-border/20 animate-pulse"
          )}
          style={{
            animationDelay: `${index * 0.1}s`,
          }}
        />
      ))}
    </div>
  </div>
);

const ChartSectionSkeleton = () => (
  <div
    className={cn(
      "relative h-[400px] overflow-hidden rounded-base border-2 border-border/20 dark:border-darkBorder/20",
      "bg-main/30 shadow-light dark:shadow-dark animate-pulse"
    )}
  >
    {/* Chart Content Simulation */}
    <div className="absolute inset-0 p-4">
      {/* Chart Title */}
      <div
        className={cn(
          "mx-auto mb-8 h-6 w-1/2 rounded-base bg-main/50 border border-border/20 animate-pulse"
        )}
      />
      {/* Chart Bars */}
      <div className="flex h-[calc(100%-4rem)] items-end justify-around gap-4">
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            className={cn(
              "w-1/6 rounded-base bg-main/50 border border-border/20 animate-pulse"
            )}
            style={{
              height: `${Math.random() * 60 + 20}%`,
              animationDelay: `${index * 0.1}s`,
            }}
          />
        ))}
      </div>
    </div>
  </div>
);

export {
  SkeletonLine,
  SkeletonMeteoriteCard,
  MeteoriteGridSkeleton,
  MapViewSkeleton,
  ChartSectionSkeleton,
};
